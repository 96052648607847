import React from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorImage from '../../assets/error.webp';
import Header from '../../components/header';
import { GrPrevious } from 'react-icons/gr';
import { RiHome2Line } from 'react-icons/ri';

const Error = () => {
  const navigate = useNavigate();

  const goBack = (to = -1) => {
    navigate(to);
  };

  return (
    <>
      <Header />
      <div className='max-w-screen-sm border-2 border-t-0 border-b-0 mx-auto px-8 md:px-12 flex flex-col items-center justify-center h-screen bg-white text-center'>
        <img
          src={ErrorImage}
          alt='Error image'
        />
        <h1 className='text-4xl font-bold mb-4 text-primary'>
          Oops! Something Went Wrong
        </h1>
        <p className='text-xl mb-6'>
          We're sorry for the inconvenience. Our team has been notified and is
          working to fix the issue.
        </p>

        <div className='flex justify-end items-center'>
          <button
            type='button'
            className='flex justify-start items-center gap-2 text-white bg-primary outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center'
            onClick={() => goBack()}
          >
            <GrPrevious />
            Go Back
          </button>
          <button
            type='button'
            className='flex justify-start items-center gap-2 py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 bg-slate-200 rounded-lg border border-gray-200'
            onClick={() => goBack('/')}
          >
            <RiHome2Line />
            Home
          </button>
        </div>
      </div>
    </>
  );
};

export default Error;
