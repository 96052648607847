import React from 'react';
import { Link } from 'react-router-dom';

import NotFound from '../../assets/404.webp';
import Header from '../../components/header';
import { RiHome2Line } from 'react-icons/ri';

const PageNotFound = () => {
  return (
    <>
      <Header />
      <div className='max-w-screen-sm border-2 border-t-0 border-b-0 mx-auto px-8 md:px-12 flex flex-col items-center justify-center h-screen bg-white text-center'>
        <img
          src={NotFound}
          alt='Error image'
        />
        <h1 className='text-4xl font-bold my-4 text-primary'>Page Not Found</h1>
        <p className='text-xl mb-6'>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>

        <Link
          to={'/'}
          type='button'
          className='flex justify-start items-center gap-2 text-white bg-primary outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center'
        >
          <RiHome2Line />
          Home
        </Link>
      </div>
    </>
  );
};

export default PageNotFound;
