import { lazy, Suspense } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import BaseLayout from '../layouts/BaseLayout';

import Loader from '../widgets/Loader';
import Error from '../pages/Error';
import PageNotFound from '../pages/404';

const Layout = lazy(() => import('../layouts/Layout'));

const Login = lazy(() => import('../pages/Login'));
const Verify = lazy(() => import('../pages/Verify'));

const DashBoard = lazy(() => import('../pages/Dashboard'));
const Yojana = lazy(() => import('../pages/Yojana'));
const Survey = lazy(() => import('../pages/Surveys'));
const Profile = lazy(() => import('../pages/Profile'));
const ProfileUpdate = lazy(() => import('../pages/Profile/Update'));
const SurveyCreate = lazy(() => import('../pages/Surveys/Create'));
const EKyc = lazy(() => import('../pages/Surveys/EKyc'));
const SurveyDetail = lazy(() => import('../pages/Surveys/[survey]'));
const SurveyUpdate = lazy(() => import('../pages/Surveys/[survey]/Update'));
const SurveyVerify = lazy(() => import('../pages/Surveys/[survey]/Verify'));

const router = (
  <Router>
    <Suspense
      fallback={
        <div className='fixed inset-0 flex flex-col justify-center items-center bg-white p-4 rounded-md'>
          <Loader />
          <p>Loading....</p>
        </div>
      }
    >
      <Routes>
        <Route
          path='/'
          element={<BaseLayout />}
        >
          <Route element={<Layout />}>
            <Route
              index
              element={<DashBoard />}
            />
            <Route
              path='/yojana'
              element={<Yojana />}
            />
            <Route
              path='/surveys'
              element={<Survey />}
            />
            <Route
              path='/surveys/e-kyc'
              element={<EKyc />}
            />
            <Route
              path='/surveys/create'
              element={<SurveyCreate />}
            />
            <Route
              path='/surveys/:survey'
              element={<SurveyDetail />}
            />
            <Route
              path='/surveys/:survey/verify'
              element={<SurveyVerify />}
            />
            <Route
              path='/surveys/:survey/edit'
              element={<SurveyUpdate />}
            />
            <Route
              path='/profile'
              element={<Profile />}
            />
            <Route
              path='/profile/update'
              element={<ProfileUpdate />}
            />
          </Route>

          <Route
            path='/auth/login'
            element={<Login />}
          />
          <Route
            path='/auth/verify'
            element={<Verify />}
          />

          <Route
            path='/error'
            element={<Error />}
          />
          <Route
            path='*'
            element={<PageNotFound />}
          />
        </Route>
      </Routes>
    </Suspense>
  </Router>
);

export default router;
