import CryptoJS from 'crypto-js';

const KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const IV = process.env.REACT_APP_ENCRYPTION_IV;

export const decrypt = (text) => {
  if (!KEY || !IV) {
    return text;
  }

  try {
    if (text == null || text === '') {
      return text;
    }

    const key = CryptoJS.enc.Utf8.parse(KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV);

    const decrypted = CryptoJS.AES.decrypt(text, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return text;
  }
};

export const encrypt = (text) => {
  if (!KEY || !IV) {
    return text;
  }

  if (text == null || text === '') {
    return text;
  }

  const key = CryptoJS.enc.Utf8.parse(KEY);
  const iv = CryptoJS.enc.Utf8.parse(IV);

  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const calculateAge = (birthDate) => {
  const birth = new Date(birthDate);

  const today = new Date();

  let age = today.getFullYear() - birth.getFullYear();

  const monthDiff = today.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
};

export const isValidAadhaar = (aadhaarNumber) => {
  if (!/^\d{12}$/.test(aadhaarNumber)) {
    return false;
  }

  const d =
    '0123456789123406789523401789563401289567401239567859876043216598710432765982104387659321049876543210';
  const p =
    '01234567891576283094580379614289160435279453126870428657390127938064157046913258';
  const inv = '0432156789';

  let c = 0;

  const reversedNumber = aadhaarNumber.split('').reverse().join('');
  const len = reversedNumber.length;

  for (let i = 0; i < len; i++) {
    const digit = reversedNumber[i];
    const pos = (i % 8) * 10 + parseInt(digit, 10);
    const m = parseInt(p[pos], 10);
    c = parseInt(d[c * 10 + m], 10);
  }

  return c === 0;
};

export const isValidIFSC = (ifsc) => {
  if (ifsc.length !== 11) {
    return false;
  }

  if (!/^[A-Za-z]{4}/.test(ifsc.substring(0, 4))) {
    return false;
  }

  if (!/^0/.test(ifsc.substring(4, 5))) {
    return false;
  }

  if (!/^[A-Za-z0-9]{6}/.test(ifsc.substring(5))) {
    return false;
  }

  return true;
};
