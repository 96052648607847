import React, { useEffect, useState } from 'react';

import SplashScreen from '../widgets/SplashScreen';

import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthContext } from '../context/useAuthContext';

const BaseLayout = () => {
  const [loading, setLoading] = useState(true);

  const { checkToken, authenticated } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const authenticated_ = await checkToken();

      if (authenticated_) {
        let prevRoute = localStorage.getItem('narishakti-route');

        try {
          if (prevRoute) {
            prevRoute = atob(prevRoute);
          }
        } catch (error) {}

        return navigate(prevRoute ?? '/');
      } else {
        setLoading(false);
      }
    };

    const timer = setTimeout(() => {
      checkAuthentication();
    }, 2000);

    return () => clearTimeout(timer);
  }, [loading, authenticated]);

  if (loading && !authenticated) {
    return <SplashScreen />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default BaseLayout;
