import React from 'react';

import ReactDOM from 'react-dom/client';

import './index.css';

import router from './routes';
import { AuthProvider } from './context/useAuthContext';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from './components/error-boundary';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <AuthProvider>{router}</AuthProvider>
      </HelmetProvider>
    </ErrorBoundary>
    <Toaster />
  </React.StrictMode>
);
