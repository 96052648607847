import React from 'react';

import Logo from '../../assets/narishakti-logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className='fixed top-0 w-full bg-white z-50'>
      <Link
        to={'/'}
        className='max-w-screen-sm mx-auto p-2 border-2 flex justify-start items-center shadow-xs gap-3'
      >
        <img
          src={Logo}
          alt='Narishakti logo'
          className='size-14 rounded-md'
        />
        <h5 className='font-semibold text-xl'>नारीशक्ती दूत</h5>
      </Link>
    </header>
  );
};

export default Header;
