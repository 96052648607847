import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { decrypt, encrypt } from '../utils/helpers';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const [constants, setConstants] = useState(null);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const setAxiosToken = () => {
    let token = localStorage.getItem('narishakti-token');

    if (!token) {
      return false;
    }

    try {
      token = decrypt(token);

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleAuthError = (error) => {
    let errorMessage = error?.response?.message || error?.message || 'Error';

    if (error?.response?.status === 401) {
      localStorage.removeItem('narishakti-user');
      localStorage.removeItem('narishakti-token');
      setUser(null);
      setAuthenticated(false);
      errorMessage = 'UnAuthenticated';
    }

    toast.error(errorMessage);
  };

  const handleApiError = (error) => {
    let errorMessage =
      error?.response?.data?.message || error?.message || 'Error';
    toast.error(errorMessage);

    if (error?.response?.data?.errors) {
      setError(error.response.data.errors);
    }
  };

  const loadConstants = async () => {
    try {
      const { data } = await axios.get(`/constants`);

      setConstants(data);
    } catch (error) {
      toast.error('Failed to load some data, please reload');
    }
  };

  const checkToken = async () => {
    if (!localStorage.getItem('narishakti-token')) {
      localStorage.removeItem('narishakti-user');

      return false;
    }

    setAxiosToken();

    setLoading(true);

    try {
      const { data } = await axios.get(`/auth/profile`);

      localStorage.setItem('narishakti-user', encrypt(JSON.stringify(data)));

      setUser(data);

      setAuthenticated(true);

      loadConstants();

      return true;
    } catch (error) {
      handleAuthError(error);

      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (user) => {
    try {
      setLoading(true);

      const { data } = await axios.put(`/auth/profile`, user);

      localStorage.setItem(
        'narishakti-user',
        encrypt(JSON.stringify(data?.data))
      );

      setUser(data?.data);

      toast.success(data?.message);

      return true;
    } catch (error) {
      handleApiError(error);

      return false;
    } finally {
      setLoading(false);
    }
  };

  const login = async ({ mobile }) => {
    try {
      setLoading(true);

      const { data } = await axios.post(`/auth/login`, {
        mobile,
      });

      localStorage.setItem(
        'narishakti-login-data',
        encrypt(JSON.stringify(data))
      );

      localStorage.removeItem('narishakti-resend-timer');

      toast.success(data?.data?.message);

      return true;
    } catch (error) {
      handleApiError(error);

      return false;
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    try {
      setLoading(true);
      let usedData = decrypt(localStorage.getItem('narishakti-login-data'));

      usedData = JSON.parse(usedData);

      const { data } = await axios.post(
        `/auth/${usedData?.data?.route_key}/resend-otp`
      );

      localStorage.setItem(
        'narishakti-login-data',
        encrypt(JSON.stringify({ data }))
      );

      localStorage.removeItem('narishakti-resend-timer');

      toast.success(data?.message);

      return true;
    } catch (error) {
      handleApiError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const verify = async ({ otp }) => {
    try {
      setLoading(true);

      let userData = decrypt(localStorage.getItem('narishakti-login-data'));
      userData = JSON.parse(userData);

      const { data } = await axios.post(
        `/auth/${userData?.data?.route_key}/verify-otp`,
        {
          otp,
        }
      );

      localStorage.setItem(
        'narishakti-user',
        encrypt(JSON.stringify(data?.data?.user))
      );

      localStorage.setItem('narishakti-token', encrypt(data?.data?.token));

      localStorage.removeItem('narishakti-resend-timer');
      localStorage.removeItem('narishakti-login-data');

      setAuthenticated(true);

      setUser(data?.data?.user);

      return true;
    } catch (error) {
      handleApiError(error);

      return false;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      setLoading(true);

      const { data } = await axios.post(`/auth/logout`);

      localStorage.removeItem('narishakti-login-data');
      localStorage.removeItem('narishakti-user');
      localStorage.removeItem('narishakti-token');

      toast.success(data?.message);

      setAuthenticated(false);

      setUser(null);

      return true;
    } catch (error) {
      handleApiError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated,
        loading,
        error,
        constants,
        login,
        resendOtp,
        verify,
        checkToken,
        logout,
        loadConstants,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
