import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.reportError(error, errorInfo);
  }

  reportError = async (error, errorInfo) => {
    try {
      console.log({
        error: error.toString(),
        errorInfo: errorInfo.componentStack,
        userAgent: navigator.userAgent,
        url: window.location.href,
      });
      //   await axios.post(`${process.env.REACT_APP_API_URL}/report-error`, {
      //     error: error.toString(),
      //     errorInfo: errorInfo.componentStack,
      //     userAgent: navigator.userAgent,
      //     url: window.location.href,
      //   });
    } catch (err) {
      console.error('Error reporting failed', err);
    }
  };

  render() {
    if (this.state.hasError) {
      return <Navigate to='/error' />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
