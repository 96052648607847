import React, { useEffect } from 'react';

import Logo from '../assets/logo.png';

import moment from 'moment';

import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../context/useAuthContext';
import { decrypt } from '../utils/helpers';

const SplashScreen = () => {
  const { authenticated } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      let loginData = localStorage.getItem('narishakti-login-data');

      if (loginData) {
        try {
          loginData = JSON.parse(decrypt(loginData));

          if (
            loginData?.data &&
            moment().isBefore(moment(loginData?.data?.expires_at))
          ) {
            return navigate('/auth/verify');
          } else {
            localStorage.removeItem('narishakti-login-data');
          }
        } catch (error) {}
      }

      return navigate('/auth/login');
    }
  }, [authenticated]);

  return (
    <div className='fixed inset-0 flex flex-col items-center justify-center bg-white z-50'>
      <img
        src={Logo}
        className='h-[78vh]'
        alt='NariShakti Doot App'
      />
      <h1 className='text-3xl font-bold text-center mt-6'>
        मुख्यमंत्री माझी लाडकी बहीण योजना
      </h1>
    </div>
  );
};

export default SplashScreen;
